import { Ducks } from '@wit/vtv-x-foundation-lib';
import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'services/i18n/translate';
import style from './ParentalRatingIcon.module.scss';

type ParentalRatingIconProps = {
  bemBlock: string;
  parentalRating?: string;
  className?: string;
  fallback?: JSX.Element;
};

enum EParentalRatingIcons {
  GR1 = 'GR1',
  GR2 = 'GR2',
  GR3 = 'GR3',
  GR4 = 'GR4',
  GR5 = 'GR5',
}

const ParentalRatingIcons: { [key: string]: JSX.Element } = {
  [EParentalRatingIcons.GR1]: (
    <svg viewBox="0 0 15 15">
      <g fontFamily="Vodacons">
        <text id="" fill="#F2F2F2">
          <tspan y="14"></tspan>
        </text>
      </g>
    </svg>
  ),
  [EParentalRatingIcons.GR2]: (
    <svg viewBox="0 0 15 15">
      <g fontFamily="Vodacons">
        <text id="" fill="#F2F2F2">
          <tspan y="14"></tspan>
        </text>
      </g>
    </svg>
  ),
  [EParentalRatingIcons.GR3]: (
    <svg viewBox="0 0 15 15">
      <g fontFamily="Vodacons">
        <text id="" fill="#F2F2F2">
          <tspan y="14.5"></tspan>
        </text>
      </g>
    </svg>
  ),
  [EParentalRatingIcons.GR4]: (
    <svg viewBox="0 0 15 15">
      <g fontFamily="Vodacons">
        <text id="" fill="#F2F2F2">
          <tspan y="14"></tspan>
        </text>
      </g>
    </svg>
  ),
  [EParentalRatingIcons.GR5]: (
    <svg viewBox="0 0 15 15">
      <g fontFamily="Vodacons">
        <text id="" fill="#F2F2F2">
          <tspan y="14"></tspan>
        </text>
      </g>
    </svg>
  ),
};

function ParentalRatingIcon({ bemBlock, parentalRating, className, fallback }: ParentalRatingIconProps) {
  const allParentalOptions = useSelector(Ducks.Dms.Selectors.getParentalControl);
  const options = allParentalOptions?.parentalRating.parentalRatingValues;
  const bemBlockId = `${bemBlock}_parentalRating`;
  const parentalRatingValue = options?.find((each) => each.ratingValue?.toString() === parentalRating);

  if (!parentalRating || !parentalRatingValue) {
    return fallback || null;
  }

  if (!parentalRatingValue.logoName || !Object.keys(ParentalRatingIcons).includes(parentalRatingValue.logoName)) {
    return (
      fallback || (
        <div id={`${bemBlockId}___label`} title={translate['setting.age.ratings.title']} className={style.boxEmpty}>
          {parentalRatingValue.label}
        </div>
      )
    );
  }

  return (
    <span className={`${className} ${style.clear}`} title={parentalRatingValue.label} id={`${bemBlockId}___icon`}>
      {ParentalRatingIcons[parentalRatingValue.logoName]}
    </span>
  );
}

export default ParentalRatingIcon;
