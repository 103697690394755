import React from 'react';

import LoginForm from './Login';
import Code from './Code';

interface Props {
  withCode?: boolean;
}

export default function Login({ withCode }: Props): JSX.Element {
  if (withCode) {
    return <Code />;
  }

  return <LoginForm />;
}
